import {Injectable} from '@angular/core';
import {UploadValidator} from '../../common/uploads/validation/upload-validator';
import {FileSizeValidation} from '../../common/uploads/validation/validations/file-size-validation';
import {convertToBytes} from '../../common/core/utils/convertToBytes';
import {FileTypeValidation} from '../../common/uploads/validation/validations/file-type-validation';
import { Settings } from '@common/core/config/settings.service';
import { Toast } from '../../common/core/ui/toast.service';
import { Translations } from '../../common/core/translations/translations.service';

@Injectable({
    providedIn: 'root'
})
export class ImageUploadValidator extends UploadValidator {
    protected readonly DEFAULT_MAX_SIZE_IMAGE_MB = 50;
    public showToast = true;
    private fallbackMaxImageSizeMB = this.DEFAULT_MAX_SIZE_IMAGE_MB;

    constructor(
        protected settings: Settings,
        protected toast: Toast,
        protected i18n: Translations
      ) {
        super(settings, toast, i18n);
    }

    protected initValidations() {
        const maxImageSizeMB = this.settings.get('uploads.max_image_size') || this.fallbackMaxImageSizeMB;

        const validations = [
            new FileSizeValidation(
                {maxSize: convertToBytes(maxImageSizeMB, 'MB')},
                this.i18n
            ),
            new FileTypeValidation({types: ['image']}, this.i18n),
        ];

        this.validations.push(...validations);
    }
}
